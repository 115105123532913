import { H2 } from '@/common/ui/h2'
import { getAddressString, seconds } from '@/common/utils/utils'
import { Dialog, DialogContent } from '@/common/ui/dialog'
import { FrankieOneKYCSmartUI } from '@/kyc/FrankieOneKYCSmartUI'
import { useEffect, useRef, useState } from 'react'
import { useClickAway } from 'react-use'
import useUserStore from '@/user/userStore'
import { AlertBox } from '@/common/ui/AlertBox'
import { useFetchKYC, useRequestKYCStatusUpdate } from '@/kyc/kycQueries'
import { AccountInfoItem } from './AccountInfoItem'
import { Button } from '@/common/ui/button'
import { X } from 'lucide-react'
import ResetPassword from '@/auth/resetPassword'
import useAuthStore from '@/auth/authStore'
import { resetPassword, signOut, type ResetPasswordOutput } from 'aws-amplify/auth'
import { toast } from 'sonner'
import { useNavigate } from 'react-router'
import { ContactSupport } from './contactSupport'
import { ContactSupportModal } from './contactSupportModal'
import { useFetchTradingAccount } from '@/trading/tradingQueries'
import { AccountDetails } from './accountDetails'
import { analytics } from '@/common/analytics/analytics.ts'

export default function AccountSettings() {
    const { user } = useUserStore()
    const setUsername = useAuthStore((state) => state.setUsername)
    const { authDetails } = useAuthStore()

    // If user logged in via Google, username would be something like Google_12XXX7X99
    // If user logged in via Email, and password, username would be something like username@email.com
    // So we are checking that if username Starts with Google,
    // and we are also checking if it does not includes @ to avoid considering emails like googleuser@email.com as Google sign in
    const signedInViaGoogle = authDetails
        ? authDetails.username.toLowerCase().startsWith('google') && !authDetails.username.includes('@')
        : false

    const { data: kyc } = useFetchKYC({ enabled: true })
    const { refetch: refetchKYC } = useFetchKYC({
        enabled: true,
        refetchInterval: seconds(20),
    })
    const { data: tradingAccount } = useFetchTradingAccount()
    const hasApprovedTradingAccount = tradingAccount?.status === 'APPROVED'
    const { mutate: requestKYCStatusUpdate, isSuccess: isStatusUpdateSuccess } = useRequestKYCStatusUpdate()
    const [isKYCOpen, setIsKYCOpen] = useState(false)
    const [isContactSupportOpen, setIsContactSupportOpen] = useState(false)
    const [isPasswordChangeOpen, setIsPasswordChangeOpen] = useState(false)
    const [isResettingPassword, setIsResettingPassword] = useState(false)
    const [sendingConfirmation, setSendingConfirmation] = useState(false)
    const closeKYCModal = () => setIsKYCOpen(false)
    const navigate = useNavigate()

    const kycModalRef = useRef(null)
    useClickAway(kycModalRef, () => {
        handleModalClose()
    })

    const passwordChangeAlertRef = useRef(null)
    useClickAway(passwordChangeAlertRef, () => {
        handlePasswordModalClose()
    })

    const passwordResetModalRef = useRef(null)
    useClickAway(passwordResetModalRef, () => {
        handlePasswordModalClose()
    })

    const handlePasswordModalClose = () => {
        setIsPasswordChangeOpen(false)
    }

    const handlePasswordResetModalClose = () => {
        setIsResettingPassword(false)
    }

    const handleModalClose = () => {
        closeKYCModal()
        window.location.reload()
    }

    const openResetPasswordForm = async () => {
        if (!user || !user.emailId) {
            toast.error('Email ID does not exist')
            return
        }
        setUsername(user.emailId)
        setSendingConfirmation(true)
        const output = await resetPassword({ username: user.emailId })
        handleResetPasswordNextSteps(output)
        setSendingConfirmation(false)
        setIsPasswordChangeOpen(false)
        setIsResettingPassword(true)
    }

    function handleResetPasswordNextSteps(output: ResetPasswordOutput) {
        const { nextStep } = output
        const codeDeliveryDetails = nextStep.codeDeliveryDetails
        switch (nextStep.resetPasswordStep) {
            case 'CONFIRM_RESET_PASSWORD_WITH_CODE':
                toast.success(`Confirmation code was sent to ${codeDeliveryDetails.deliveryMedium?.toLowerCase()}`)
                // Collect the confirmation code from the user and pass to confirmResetPassword.
                break
            case 'DONE':
                console.log('Successfully reset password.')
                break
        }
    }

    useEffect(() => {
        const frankieEvents = [
            'SCREEN:SUCCESS',
            'SCREEN:FAILURE',
            'SCREEN:PENDING_VERFICATION',
            'SCREEN:ERROR',
            'FF_CHECK_RESULT',
            'FF_CHECK_ERROR',
        ]
        frankieEvents.forEach((event) => {
            window.addEventListener(event, (e: any) => {
                console.log(event, e.detail)
                requestKYCStatusUpdate()
            })
        })
    }, [requestKYCStatusUpdate])

    useEffect(() => {
        if (!isStatusUpdateSuccess) return
        refetchKYC()
    }, [isStatusUpdateSuccess, refetchKYC])

    async function handleReset() {
        await signOut()
        toast.success('You have reset the password successfully. Please login again!')
        navigate('/')
    }

    if (!user) return null

    return (
        <div>
            {kyc?.status && kyc?.status !== 'VERIFICATION_SUCCESS' && (
                <div className="mb-12">
                    {kyc?.status === 'UNVERIFIED' && (
                        <AlertBox
                            heading="Please verify your identity"
                            text="You have not verified your identity as of yet. In order to trade on the SIX platform, we'll need 5 minutes of your time to verify your identity."
                            buttonText="Verify identity"
                            onClick={() => {
                                analytics.track('kyc_resumed_from_profile')
                                setIsKYCOpen(true)
                            }}
                        />
                    )}
                    {(kyc?.status === 'INITIATED' || kyc?.status === 'RE_INITIATED') && (
                        <AlertBox
                            heading="Please complete your identity verification"
                            text="You have not completed verification of your identity as of yet. In order to trade on the SIX platform, we'll need 5 minutes of your time to verify your identity."
                            buttonText="Continue identity verification"
                            onClick={() => {
                                analytics.track('kyc_resumed_from_profile')
                                setIsKYCOpen(true)
                            }}
                        />
                    )}
                    {kyc?.status === 'UNDER_VERIFICATION' && (
                        <AlertBox
                            className="bg-accent"
                            heading="We're currently verifying your identity"
                            text="We're currently verifying your identity. This process usually only takes a few minutes, but sometimes takes up to 2 business days. We'll notify you once it's complete."
                        />
                    )}
                    {kyc?.status === 'VERIFICATION_FAILED' && (
                        <AlertBox
                            className="bg-accent"
                            heading="We were unable to automatically verify your identity"
                            text="Our team will be in touch with you shortly to assist you with the verification process."
                        />
                    )}
                </div>
            )}
            {kyc?.status === 'VERIFICATION_SUCCESS' && !hasApprovedTradingAccount && (
                <AlertBox
                    className="bg-accent"
                    heading="Your trading account is still being set up"
                    text="This usually takes around 20 minutes in business hours, or by 10:30am on the next business day."
                />
            )}

            {hasApprovedTradingAccount && <AccountDetails account={tradingAccount} />}

            <H2 className="mt-5">Personal details</H2>
            <ul className="mt-2 mb-16">
                {user.firstName && user.lastName && (
                    <AccountInfoItem title="Name" info={`${user.firstName} ${user.middleName ? `${user.middleName} ` : ``}${user.lastName}`} />
                )}
                <AccountInfoItem title="Email" info={user.emailId} />
                {user.mobile && <AccountInfoItem title="Mobile Number" info={user.mobile} />}
                {user.addresses?.residentialAddress && (
                    <AccountInfoItem title="Address" info={getAddressString(user.addresses?.residentialAddress)} />
                )}
                {user.addresses?.postalAddress && (
                    <AccountInfoItem title="Postal Address" info={getAddressString(user.addresses?.postalAddress)} />
                )}
                {!signedInViaGoogle && (
                    <AccountInfoItem
                        title="Password"
                        info={'•'.repeat(12)}
                        editHandler={() => setIsPasswordChangeOpen(true)}
                    />
                )}
            </ul>

            {isKYCOpen && (
                <Dialog open={isKYCOpen}>
                    <div className="py-10 h-screen">
                        <DialogContent
                            ref={kycModalRef}
                            onClose={handleModalClose}
                            className="p-0 w-[400px] sm:w-[600px] lg:w-[800px] xl:w-[1000px] h-full"
                        >
                            <FrankieOneKYCSmartUI />
                        </DialogContent>
                    </div>
                </Dialog>
            )}

            {isPasswordChangeOpen && (
                <Dialog open={isPasswordChangeOpen}>
                    <div className="py-10 h-screen">
                        <DialogContent
                            ref={passwordChangeAlertRef}
                            onClose={handlePasswordModalClose}
                            className="px-5 py-6 max-h-[90vh] w-[94vw] max-w-[680px] overflow-y-auto xs:p-20"
                        >
                            <H2 className="text-center">Change your password</H2>
                            <p className="text-center py-5">
                                In order to change your password, You must have access to your email. Do you wish to
                                continue?
                            </p>
                            <div className="flex justify-between">
                                <Button
                                    variant="secondary"
                                    onClick={openResetPasswordForm}
                                    loading={sendingConfirmation}
                                >
                                    Yes, I have access to my email
                                </Button>
                                <Button
                                    variant="ghost"
                                    className="hover:bg-transparent"
                                    onClick={handlePasswordModalClose}
                                >
                                    <div className="bg-accent rounded-full p-1">
                                        <X className="h-5 w-5" />
                                    </div>
                                    Cancel
                                </Button>
                            </div>
                        </DialogContent>
                    </div>
                </Dialog>
            )}

            {isResettingPassword && (
                <Dialog open={isResettingPassword}>
                    <div className="py-10 h-screen">
                        <DialogContent
                            className="px-5 py-12 max-h-[90vh] w-[94vw] max-w-[680px] overflow-y-auto xs:p-20"
                            onClose={handlePasswordResetModalClose}
                        >
                            <ResetPassword onReset={handleReset} />
                        </DialogContent>
                    </div>
                </Dialog>
            )}
            <ContactSupport showModal={() => setIsContactSupportOpen(true)} />
            {isContactSupportOpen && <ContactSupportModal closeModal={() => setIsContactSupportOpen(false)} />}
        </div>
    )
}
